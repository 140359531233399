<template>
  <section class="hero">
    <div class="title-hero">
      Próximos Partidos
    </div>
    <div class="hero-body is-paddingless">
      <div class="tournaments">
        <div class="tournaments__selector">
          <BSelect placeholder="Selecciona uno" :value="tournamentSelected" expanded @input="setTournamentSelected">
            <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament">
              {{ tournament.name }}
            </option>
          </BSelect>
        </div>
        <div class="tournaments__logo">
          <img :src="tournamentSelected.logo" alt="Tournament logo" />
        </div>
      </div>
      <hr class="tournament-hr" />
      <div class="container">
        <!-- League rounds -->
        <div class="columns is-mobile is-vcentered is-marginless">
          <div class="column header-letter has-text-centered element-paginate">
            <div>J</div>
          </div>
          <!-- Rounds horizontal scroll -->
          <div class="column is-10 paginate has-text-centered element-paginate">
            <div class="rounds-scroll-button left-button is-clickable" @click="onClickScrollLeft">
              <img src="/icons/icn-next-grey.svg" alt="previous" />
            </div>
            <div class="next-games-rounds">
              <div
                v-for="key in Object.keys(fullTournamentSchedule)"
                class="round-item is-clickable"
                :id="`si${key}`"
                :style="key === selectedRound ? roundSelectedStyle : ''"
                :key="key"
                @click="updateSelectedRound(key)"
              >
                {{ key }}
              </div>
            </div>
            <div class="rounds-scroll-button is-clickable" @click="onClickScrollRight">
              <img src="/icons/icn-next-grey.svg" alt="next" />
            </div>
          </div>
        </div>
        <!-- Round dates -->
        <template v-for="(roundDate, i) in fullTournamentSchedule[selectedRound]">
          <div
            v-for="(game, index) in roundDate.games"
            :key="i + '-' + index"
            class="columns is-mobile is-gapless round-schedule"
          >
            <div class="column is-2">
              <div class="date-file info-live" v-if="!!game.is_live">
                <div class="day-of-week is-uppercase">{{ roundDate.date | toDayOfWeek }}</div>
                <div class="day-of-month">{{ roundDate.date | toDayOfMonth }}</div>
                <div class="month is-uppercase">{{ roundDate.date | toMonth }}</div>
                <div class="label-live is-uppercase"><span class="oval"></span>LIVE</div>
              </div>
              <div class="date-file" v-else>
                <div class="day-of-week is-uppercase">{{ roundDate.date | toDayOfWeek }}</div>
                <div class="day-of-month">{{ roundDate.date | toDayOfMonth }}</div>
                <div class="month is-uppercase">{{ roundDate.date | toMonth }}</div>
              </div>
            </div>
            <!-- Match day games -->
            <div class="column is-10">
              <div class="match-file">
                <div class="game-info is-clickable columns is-gapless" @click="onGameClicked(game)">
                  <div class="column is-11">
                    <div class="game-info-data">
                      <div class="column is-3 no-padding">
                        <img :src="game.home_team ? game.home_team.logo : ''" class="teams" alt="Equipo local" />
                      </div>
                      <div class="column is-6 teams-vs-time no-padding">
                        <div class="teams-vs">
                          <div class="acronym">{{ game.home_team ? game.home_team.acronym : '' }}</div>
                          <div class="vs" v-if="game.game_status == 4">VS</div>
                          <div class="vs no-padding" v-else>
                            <span class="score-text">{{ game.score[0] }} - {{ game.score[1] }}</span>
                          </div>
                          <div class="acronym">{{ game.visiting_team ? game.visiting_team.acronym : '' }}</div>
                        </div>
                        <div class="time">{{ game.schedule | removeSecondsFromTime }} hrs.</div>
                      </div>
                      <div class="column is-3 no-padding">
                        <img
                          :src="game.visiting_team ? game.visiting_team.logo : ''"
                          class="teams"
                          alt="Equipo visitante"
                        />
                      </div>
                    </div>
                  </div>
                  <div class=" is-1">
                    <div class="eye-identifier" :class="{ live: game.is_live === 1 }"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { BSelect } from 'buefy/dist/esm/select';

let intersectionObserver = null;
let nextGamesRounds = null;
let observerEntries = new Map();
export default {
  components: {
    BSelect,
  },
  name: 'MatchesMobile',
  // props: {
  //   closestMatchDay: {
  //     type: String,
  //     required: true,
  //   },
  // },
  data() {
    return {
      roundSelectedStyle: {
        'background-color': '#cce8b5',
      },
    };
  },
  watch: {
    nextGamesSchedule() {
      this.setIsSectionLoading(false);
      const vm = this;
      // Set a little timeout to wait for elements to be created in the DOM
      setTimeout(() => {
        vm.setUpIntersectionObserver();
      }, 1000);
    },
    fullTournamentSchedule() {
      this.setIsSectionLoading(false);
      const vm = this;
      // Set a little timeout to wait for elements to be created in the DOM
      setTimeout(() => {
        vm.setUpIntersectionObserver();
      }, 1000);
      // this.updateSelectedRound(this.closestMatchDay);
    },
  },
  computed: {
    ...mapState('general', ['tournaments', 'tournamentSelected']),
    ...mapGetters('leagueGeneral', ['fullTournamentSchedule', 'nextGamesSchedule']),
    ...mapState(['isSectionLoading']),
    ...mapState('leagueGeneral', ['selectedRound']),
  },
  methods: {
    ...mapMutations(['setIsSectionLoading']),
    ...mapMutations('leagueGeneral', ['updateSelectedRound']),
    ...mapMutations('general', ['setTournamentSelected']),
    ...mapActions('routes', ['goToGame']),
    setUpIntersectionObserver() {
      if (!nextGamesRounds) nextGamesRounds = document.querySelector('.next-games-rounds');
      const scrollItems = document.querySelectorAll('.round-item');
      if (scrollItems.length === 0) return;
      // Creation of an intersection observer
      // see https://developer.mozilla.org/es/docs/Web/API/Intersection_Observer_API
      observerEntries.clear();
      intersectionObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            observerEntries.set(entry.target.id, entry);
          });
        },
        {
          root: nextGamesRounds,
          threshold: 1,
        },
      );
      scrollItems.forEach(scrollItem => intersectionObserver.observe(scrollItem));
    },
    onClickScrollRight() {
      let startRatio = 0;
      for (let entry of observerEntries.values()) {
        // When this conditional compares to true, means that current entry
        // is not fully visible after another that is visible, so this entry's
        // x position is where we need scroll to
        if (startRatio === 1 && entry.intersectionRatio < 1) {
          nextGamesRounds.scrollTo({
            top: 0,
            left: entry.target.offsetLeft - entry.rootBounds.x,
            behavior: 'smooth',
          });
          break;
        }
        startRatio = entry.intersectionRatio;
      }
    },
    onClickScrollLeft() {
      let entryAux = null;
      for (let entry of observerEntries.values()) {
        // When this conditional compares to true, means that current entry
        // is fully visible after another that is not fully visible, so the partially visible
        // entry's x position is where we need scroll to
        if (entryAux && entryAux.intersectionRatio < 1 && entry.intersectionRatio === 1) {
          const left = entryAux.target.offsetLeft - entry.rootBounds.x;
          nextGamesRounds.scrollTo({
            top: 0,
            left: left - entryAux.rootBounds.width + entryAux.boundingClientRect.width,
            behavior: 'smooth',
          });
          break;
        }
        entryAux = entry;
      }
    },
    onGameClicked(game) {
      this.goToGame({ gameId: game.game_id, currentSeasonId: game.season_id });
    },
  },
};
</script>

<style lang="scss" scoped>
$color-grey: #f1f1f1;
$light-border: solid 2px $color-grey;

.match-file {
  border: $light-border;
  border-radius: 12px;
  margin: 0px 5px;
  padding: 10px 2px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-file {
  padding: 10px 0px;
  border: $light-border;
  border-radius: 8px;
  margin: 0px 5px;
  background-color: $color-grey;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.hero-body {
  border: $light-border;
}

.hero-body {
  border: $light-border;
}

.section {
  padding: 0px;
}

.title-hero {
  font-family: 'Avenir-Demi';
  font-size: 1.3rem;
  text-align: center;
  margin: 8px;
}

.paginate {
  display: flex;
  padding: 0.4rem 0.1rem;
  font-family: Circular-Std-Book;
}

.header-letter {
  background-color: $color-grey;
}

.rounds-scroll-button {
  height: 40px;
  width: 40px;
  padding-top: 10px;
  flex-shrink: 0;
  background-color: $color-grey;

  &.left-button > img {
    // Chrome
    -webkit-transform: rotate(180deg);
    // Firefox
    -moz-transform: rotate(180deg);
    // Opera
    -o-transform: rotate(180deg);
    // W3C compliant browsers
    transform: rotate(180deg);
  }

  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  }
}

.next-games-rounds {
  flex-grow: 1;
  display: flex;
  border: solid 1px $color-grey;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  /* Hide scroll bar in firefox*/
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.round-item {
  width: 30px;
  height: 29px;
  margin: 0 6px;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 32px;
  flex-shrink: 0;
}

.date-file {
  .day-of-week {
    font-size: 9px;
    font-weight: 500;
    letter-spacing: -0.03px;
  }

  .day-of-month {
    font-family: 'Circular-Std';
    font-size: 35px;
    font-weight: 900;
    line-height: 44px;
    letter-spacing: -0.17px;
    vertical-align: text-bottom;
  }

  .month {
    font-size: 9px;
  }
}

@media (max-width: 400px) {
  .date-file {
    .day-of-week {
      font-size: 8px !important;
    }
    .month {
      font-size: 8px !important;
    }
  }
}

@media (max-width: 350px) {
  .date-file {
    .day-of-week {
      font-size: 7px !important;
    }
    .month {
      font-size: 7px !important;
    }
  }
}

.game-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4px;

  &:hover {
    background-color: #f3f3f3;
    border-radius: 12px;
  }

  .game-info-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px;
  }

  .teams {
    padding: 0px 4px;
  }

  .eye-identifier {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #3c3c3c;
    border-radius: 7.2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
    background-image: url('/assets/icons/icn_info.png');
    background-repeat: no-repeat;
    background-position: center center;
  }
  .eye-identifier.live {
    background-color: #77a405;
  }
}

.live-game {
  border-radius: 9px;
  background-color: #282828;
  color: white;
}

.teams-vs-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 12px;
  align-items: center;

  & > .teams-vs {
    font-family: 'Circular-Std';
    letter-spacing: -0.05px;
    display: flex;
    align-items: baseline;

    .acronym {
      font-size: 1rem;
      font-weight: bold;
    }

    .vs {
      width: 30px;
      margin: 0 14px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  & > .time {
    font-family: 'Avenir-Demi';
    font-size: 0.8rem;
    margin-top: 4px;
    letter-spacing: -0.04px;
  }
}

.falcon-logo {
  padding-top: 16px;
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: right;

  & > img {
    width: 156px;
  }
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1rem;
}

@media screen and (max-width: 480px) {
  .no-padding {
    padding: 0px 0px !important;
  }
  .teams-vs-time {
    & > .teams-vs {
      .acronym {
        font-size: 14px;
        font-weight: bold;
      }
    }
    & > .time {
      font-size: 14px;
    }
  }

  .game-info {
    .eye-identifier {
      width: 38px;
      height: 38px;
    }
    .teams {
      padding: 0px 22px !important;
    }
  }
}
@media screen and (max-width: 420px) {
  .game-info {
    .teams {
      padding: 0px 15px !important;
    }
  }
  .teams-vs-time {
    & > .teams-vs {
      .acronym {
        font-size: 13px;
        font-weight: bold;
      }
    }
    & > .time {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 374px) {
  .date-file .day-of-month {
    font-size: 20px;
    line-height: 20px;
  }
  .no-padding {
    padding: 0px 0px !important;
  }
  .game-info {
    .teams {
      padding: 0px 8px !important;
    }
  }
  .teams-vs-time {
    & > .teams-vs {
      .acronym {
        font-size: 12px;
        font-weight: bold;
      }
    }
    & > .time {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 320px) {
  .game-info {
    .teams {
      padding: 0px !important;
    }
  }
}
.element-paginate {
  font-family: 'Avenir-Demi';
}
.oval {
  width: 8px;
  height: 8px;
  margin-right: 3px;
  background-color: #7ed321;
  border-radius: 50%;
  display: inline-block;
}
.info-live {
  background-color: #34a7ff;
  color: white;
  .time-unity {
    font-size: 9px;
    font-weight: 500;
    letter-spacing: -0.03px;
  }
  .time-live {
    font-family: 'Circular-Std';
    font-size: 35px;
    font-weight: 900;
    line-height: 44px;
    letter-spacing: -0.17px;
    vertical-align: text-bottom;
  }
  .label-live {
    font-size: 8px;
  }
}
.hero {
  margin-top: 10px;
}

.tournaments {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 8px;

  &__selector {
    width: 250px;
    margin: 0 10px;
  }

  &__logo {
    height: 30px;
    margin: 0 10px 0 0;

    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.tournament-hr {
  margin: 12px auto;
  width: 95%;
  height: 1px;
  border-width: 0;
  color: #d5cfcf;
  background-color: #d5cfcf;
}
</style>
