<template>
  <div>
    <div class="main-container is-centered is-hidden-mobile">
      <div class="main-container__tournaments">
        <div class="is-uppercase tournament-text">torneo</div>
        <div class="main-container__tournaments__selector">
          <BSelect placeholder="Selecciona uno" :value="tournamentSelected" expanded @input="setTournamentSelected">
            <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament">
              {{ tournament.name }}
            </option>
          </BSelect>
        </div>
        <div class="main-container__tournaments__logo">
          <img :src="tournamentSelected.logo" alt="Tournament logo" />
        </div>
      </div>
      <div class="next-games-container-desktop is-bordered-box-shadow-main">
        <!-- League rounds -->
        <div class="next-games-grid">
          <div style="line-height: 40px;">JORNADA</div>
          <!-- Rounds horizontal scroll -->
          <div class="rounds-controller">
            <div class="rounds-scroll-button left-button is-clickable" @click="onClickScrollLeft">
              <img src="/icons/icn-next-grey.svg" alt="previous" />
            </div>
            <div class="next-games-rounds-desktop">
              <div
                v-for="key in Object.keys(fullTournamentSchedule)"
                class="round-item-desktop is-clickable btn-jornada"
                :id="`si${key}`"
                :style="key == selectedRound ? roundSelectedStyle : ''"
                :key="key"
                @click="updateSelectedRound(key)"
              >
                {{ key }}
              </div>
            </div>
            <div class="rounds-scroll-button is-clickable" @click="onClickScrollRight">
              <img src="/icons/icn-next-grey.svg" alt="next" />
            </div>
          </div>
          <!-- Round detail -->
          <div class="round-schedule-container">
            <!-- Round dates -->
            <div
              v-for="(roundDate, index) in fullTournamentSchedule[selectedRound]"
              :key="index"
              class="round-schedule"
            >
              <div v-if="roundDate.date !== 'canceled'" class="match-day">
                <div class="day-of-week is-uppercase">{{ roundDate.date | toDayOfWeek }}</div>
                <div class="day-of-month">{{ roundDate.date | toDayOfMonth }}</div>
                <div class="month is-uppercase">{{ roundDate.date | toMonth }}</div>
              </div>
              <div v-else class="round-canceled-games">
                <div class="game-text is-uppercase">partido</div>
                <div class="canceled-text is-uppercase">cancelado</div>
              </div>
              <!-- Match day games -->
              <div class="match-day-games">
                <div
                  v-for="(game, index) in roundDate.games"
                  :key="index"
                  class="game-info is-clickable btn-partido"
                  :class="{
                    'postponed-game': game.game_status === 2,
                    'canceled-game': game.game_status === 3,
                    'live-game': game.is_live === 1,
                  }"
                  @click="onGameClicked(game)"
                >
                  <div
                    v-if="game.game_status === 2 || game.game_status === 3"
                    class="game-status is-uppercase"
                    :class="{ postponed: game.game_status === 2, canceled: game.game_status === 3 }"
                  >
                    {{ game.game_status | toGameStatusString }}
                  </div>
                  <div class="team-logo-container">
                    <img :src="game.home_team ? game.home_team.logo : ''" alt="Equipo local" />
                  </div>
                  <div class="teams-vs-time">
                    <div class="teams-vs">
                      <div class="acronym">{{ game.home_team ? game.home_team.acronym : '' }}</div>
                      <div class="vs" v-if="game.game_status == 4">VS</div>
                      <div class="vs" v-else>{{ game.score[0] }} - {{ game.score[1] }}</div>
                      <div class="acronym">{{ game.visiting_team ? game.visiting_team.acronym : '' }}</div>
                    </div>
                    <div v-if="game.game_status === 3" class="time">
                      ---
                    </div>
                    <div v-else class="time">
                      <span v-if="!!game.is_live" class="oval"></span>
                      {{ game.schedule | removeSecondsFromTime }} hrs.
                    </div>
                  </div>
                  <div class="team-logo-container">
                    <img :src="game.visiting_team ? game.visiting_team.logo : ''" alt="Equipo visitante" />
                  </div>
                  <div class="eye-identifier" :class="{ live: game.is_live === 1 }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="next-games-container-desktop__tournaments">
          <div class="next-games-container-desktop__tournaments__logo">
            <img :src="tournamentSelected.logo" alt="Tournament logo" />
          </div>
          <hr />
          <div class="is-uppercase tournament-text">torneo</div>
          <div class="next-games-container-desktop__tournaments__selector">
            <BSelect placeholder="Selecciona uno" :value="tournamentSelected" expanded @input="setTournamentSelected">
              <option v-for="tournament in tournaments" :key="tournament.id" :value="tournament">
                {{ tournament.name }}
              </option>
            </BSelect>
          </div>
        </div>
      </div>
    </div>
    <MatchesMobile class="is-hidden-tablet" :closestMatchDay="closestMatchDay" />
  </div>
</template>

<script>
import { connectSocket } from '@/utils/socketConnection';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import MatchesMobile from '@/components/Elements/MatchesMobile.vue';
import { BSelect } from 'buefy/dist/esm/select';
let observerEntriesDesktop = new Map();
let roundScheduleContainer = null;
let nextGamesContainerDesktop = null;
let nextGamesRoundsDesktop = null;
let intersectionObserverDesktop = null;

import { clickBtnPartido } from '@/utils/analytics';

export default {
  name: 'Matches',
  components: {
    MatchesMobile,
    BSelect,
  },
  data() {
    return {
      roundSelectedStyle: {
        'background-color': '#cce8b5',
      },
    };
  },
  async created() {
    try {
      window.addEventListener('resize', this.onResize);
      this.setIsSectionLoading(true);
      await this.prepareCleanAllStatistics();
      if (this.tournamentSelected && this.tournamentSelected.seasons && this.tournamentSelected.seasons.length > 0) {
        await this.$store.dispatch('leagueGeneral/getCurrentSeasonGames');
      }
      await this.$store.dispatch('general/fetchTournaments');
    } catch (err) {
      throw new Error(err);
    } finally {
      setTimeout(() => {
        if (this.$route.name === 'matches') {
          this.setIsSectionLoading(false);
        }
      }, 1700);
    }
  },
  mounted() {
    this.onResize();
    if (this.getPremiumAccount) connectSocket();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    roundScheduleContainer = null;
    nextGamesContainerDesktop = null;
    nextGamesRoundsDesktop = null;
    intersectionObserverDesktop = null;
    observerEntriesDesktop = new Map();
  },
  watch: {
    // nextGamesSchedule() {
    //   this.setIsSectionLoading(false);
    //   const vm = this;
    //   // Set a little timeout to wait for elements to be created in the DOM
    //   setTimeout(() => {
    //     vm.setUpIntersectionObserver();
    //   }, 1000);
    //   this.updateSelectedRound(this.closestMatchDay);
    // },
    fullTournamentSchedule() {
      this.setIsSectionLoading(false);
      const vm = this;
      // Set a little timeout to wait for elements to be created in the DOM
      setTimeout(() => {
        vm.setUpIntersectionObserver();
      }, 1000);
      // this.updateSelectedRound(this.closestMatchDay);
    },
    selectedRound() {
      if (!roundScheduleContainer) {
        roundScheduleContainer = document.querySelector('.round-schedule-container');
      }
      roundScheduleContainer.scrollTop = 0;
    },
    closestMatchDay(value) {
      this.updateSelectedRound(value);
    },
    getPremiumAccount(value) {
      if (this.getPremiumAccount && value) connectSocket();
    },
    tournamentSelected: {
      immediate: true,
      async handler(newValue) {
        try {
          if (newValue && newValue.id && newValue.seasons && newValue.seasons.length > 0) {
            this.setIsSectionLoading(true);
            this.setSeasonId(newValue.seasons[0].id);
            await this.$store.dispatch('leagueGeneral/getCurrentTournamentGames', { tournamentId: newValue.id });
          }
        } catch (e) {
          throw new Error(e);
        } finally {
          if (this.$route.name === 'matches') {
            this.setIsSectionLoading(false);
          }
        }
      },
    },
  },
  computed: {
    ...mapState('general', ['tournaments', 'tournamentSelected']),
    ...mapGetters('leagueGeneral', ['fullTournamentSchedule', 'nextGamesSchedule', 'closestMatchDay']),
    ...mapState('leagueGeneral', ['selectedRound']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    ...mapMutations(['setIsSectionLoading']),
    ...mapMutations('nextGeneral', ['prepareCleanAllStatistics', 'setSeasonId']),
    ...mapMutations('leagueGeneral', ['updateSelectedRound']),
    ...mapMutations('general', ['setTournamentSelected']),
    ...mapActions('routes', ['goToGame']),
    onResize() {
      if (!nextGamesContainerDesktop && !nextGamesRoundsDesktop) {
        nextGamesContainerDesktop = document.querySelector('.next-games-container-desktop');
        nextGamesRoundsDesktop = document.querySelector('.next-games-rounds-desktop');
      }
      // Change nextGamesRoundsDesktop div width, subtract 80px from scroll buttons, 112px from JORNADA div
      // and 48px from internal padding.
      nextGamesRoundsDesktop.style.width = `${nextGamesContainerDesktop.clientWidth - 240}px`;
    },
    setUpIntersectionObserver() {
      nextGamesRoundsDesktop = document.querySelector('.next-games-rounds-desktop');
      const scrollItems = document.querySelectorAll('.round-item-desktop');
      if (scrollItems.length === 0) return;
      // Creation of an intersection observer
      // see https://developer.mozilla.org/es/docs/Web/API/Intersection_Observer_API
      observerEntriesDesktop.clear();
      intersectionObserverDesktop = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            observerEntriesDesktop.set(entry.target.id, entry);
          });
        },
        {
          root: nextGamesRoundsDesktop,
          threshold: 1,
        },
      );
      scrollItems.forEach(scrollItem => {
        intersectionObserverDesktop.observe(scrollItem);
      });
    },
    onClickScrollRight() {
      let startRatio = 0;
      for (let entry of observerEntriesDesktop.values()) {
        // When this conditional compares to true, means that current entry
        // is not fully visible after another that is visible, so this entry's
        // x position is where we need scroll to
        if (startRatio === 1 && entry.intersectionRatio < 1) {
          nextGamesRoundsDesktop.scrollTo({
            top: 0,
            left: entry.target.offsetLeft - entry.rootBounds.x,
            behavior: 'smooth',
          });
          break;
        }
        startRatio = entry.intersectionRatio;
      }
    },
    onClickScrollLeft() {
      let entryAux = null;
      for (let entry of observerEntriesDesktop.values()) {
        // When this conditional compares to true, means that current entry
        // is fully visible after another that is not fully visible, so the partially visible
        // entry's x position is where we need scroll to
        if (entryAux && entryAux.intersectionRatio < 1 && entry.intersectionRatio === 1) {
          const left = entryAux.target.offsetLeft - entry.rootBounds.x;
          nextGamesRoundsDesktop.scrollTo({
            top: 0,
            left: left - entryAux.rootBounds.width + entryAux.boundingClientRect.width,
            behavior: 'smooth',
          });
          break;
        }
        entryAux = entry;
      }
    },
    onGameClicked(game) {
      clickBtnPartido(game.game_id, this.getPremiumAccount, this.getIsGolstats);
      this.goToGame({ gameId: game.game_id, currentSeasonId: game.season_id });
    },
  },
};
</script>

<style lang="scss" scoped>
$game-border-radius: 9px;
$canceled-game-color: #ff7070;
$postponed-game-color: #3ac5d9;

.main-container {
  margin: 20px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__tournaments {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .tournament-text {
      margin-right: 20px;
    }

    &__selector {
      width: 350px;
    }

    &__logo {
      height: 30px;
      width: 100%;
      & > img {
        height: 100%;
      }
    }

    @media (min-width: 1280px) {
      display: none;
    }
  }
}

.next-games-container-desktop {
  margin: 13px 10px;
  padding: 24px 24px 0;
  height: 820px;
  //min-height: calc(100vh - 240px);
  position: relative;
  width: 100%;

  @media (min-width: 872px) {
    width: 872px;
  }

  &__tournaments {
    position: absolute;
    padding: 20px 10px;
    top: 0;
    width: 200px;
    left: -200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > hr {
      margin: 12px 0 20px;
      width: 100%;
      height: 1px;
      border-width: 0;
      color: #d5cfcf;
      background-color: #d5cfcf;
    }

    &__logo {
      height: 30px;
      width: 100%;
      & > img {
        height: 100%;
      }
    }

    &__selector {
      margin: 4px 0;
    }

    @media (max-width: 1279px) {
      display: none;
    }
  }
}

.tournament-text {
  color: #333333;
  font-family: Avenir-Regular, sans-serif;
  font-weight: 500;
}

.next-games-grid {
  //height: 510px;
  height: calc(100vh - 300px);
  max-width: 824px;
  display: grid;
  grid-template-columns: 112px 1fr;
  grid-template-rows: min-content auto;
}

.rounds-controller {
  display: flex;
  width: 100%;
}

.rounds-scroll-button {
  height: 40px;
  width: 40px;
  padding-top: 10px;
  flex-shrink: 0;
  text-align: center;
  background-color: #f1f1f1;

  &.left-button > img {
    // Chrome
    -webkit-transform: rotate(180deg);
    // Firefox
    -moz-transform: rotate(180deg);
    // Opera
    -o-transform: rotate(180deg);
    // W3C compliant browsers
    transform: rotate(180deg);
  }

  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  }
}

.rounds-scroll-button:hover {
  background-color: #dbdbdb;
}

.next-games-rounds-desktop {
  width: 632px;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  /* Hide scroll bar in firefox*/
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.round-item-desktop {
  width: 30px;
  height: 29px;
  margin: 0 6px;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 32px;
  flex-shrink: 0;
  text-align: center;
  /*background-color: #cce8b5;*/

  &:hover {
    background-color: #f3f3f3;
  }
}

.round-schedule-container {
  overflow: auto;
  grid-column-start: 1;
  grid-column-end: 3;
}

.round-schedule {
  display: flex;
}

.round-canceled-games {
  width: 132px;
  background-color: $canceled-game-color;
  border: solid 0.5px $canceled-game-color;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 13px;

  & > .game-text {
    font-family: 'Avenir-Regular';
    font-size: 8px;
    font-weight: 500;
    letter-spacing: -0.03px;
  }

  & > .canceled-text {
    font-family: 'Circular-Std';
    font-size: 13px;
    font-weight: 900;
    letter-spacing: -0.05px;
  }
}

.match-day {
  width: 132px;
  background-color: #d8d8d8;
  border: solid 0.5px #c2c2c2;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 13px;
  color: #434343;

  & > .day-of-week {
    font-size: 9px;
    font-weight: 500;
    letter-spacing: -0.03px;
  }

  & > .day-of-month {
    font-family: 'Circular-Std';
    font-size: 44px;
    font-weight: 900;
    line-height: 44px;
    letter-spacing: -0.17px;
    vertical-align: text-bottom;
  }

  & > .month {
    font-size: 11px;
  }
}

.match-day-games {
  width: 100%;
  padding: 8px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: solid 0.5px #c2c2c2;
  @media (max-width: 872px) {
    padding: 8px 10px;
  }
  @media (max-width: 800px) {
    padding: 8px 1px;
  }
}

.game-info {
  margin: 13px 8px;
  display: flex;
  min-height: 60px;
  position: relative;
  min-width: 295px !important;

  @media (max-width: 872px) {
    min-width: 288px !important;
    margin: 13px 0px;
  }
  @media (max-width: 800px) {
    min-width: 280px !important;
    margin: 13px 0px;
  }

  &:hover {
    background-color: #f3f3f3;
    border-radius: 12px;
  }

  & > .game-status {
    width: 60px;
    position: absolute;
    top: 3px;
    left: 50%;
    margin-left: -30px;
    font-family: 'Circular-Std';
    font-size: 7px;
    font-weight: bold;
    letter-spacing: -0.02px;
    text-align: center;
    color: white;

    &.canceled {
      background-color: $canceled-game-color;
      border-radius: 3px;
    }

    &.postponed {
      background-color: $postponed-game-color;
      border-radius: 3px;
    }
  }
}

.live-game {
  border-radius: $game-border-radius;
  background-color: #282828;
  color: white;
}

.postponed-game {
  border-radius: $game-border-radius;
  border: solid 2px $postponed-game-color;
}

.canceled-game {
  border-radius: $game-border-radius;
  border: solid 2px $canceled-game-color;
}

.team-logo-container {
  height: 42px;
  width: 42px;
  margin: 5px 9px;
  background-color: transparent;

  & > img {
    height: 100%;
  }
}

.teams-vs-time {
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  align-items: center;

  & > .teams-vs {
    font-family: 'Circular-Std';
    letter-spacing: -0.05px;
    display: flex;
  }

  & > .time {
    font-family: 'Avenir-Demi';
    margin-top: 4px;
    font-size: 10px;
    letter-spacing: -0.04px;
  }
}
.eye-identifier {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 8px;
  background-color: #3c3c3c;
  border-radius: 7.2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
  margin: 10px 0 19px 22px;
  background-image: url('/assets/icons/icn_info.png');
  background-repeat: no-repeat;
  background-position: center center;
  @media (max-width: 800px) {
    right: 3px;
    width: 37px;
    height: 37px;
    margin: 14px 0 19px 22px;
  }
}
.eye-identifier.live {
  background-color: #77a405;
}
.acronym {
  font-size: 14px;
  font-weight: bold;
}

.vs {
  margin: 0 24px;
  font-size: 13px;
  font-weight: 500;
}

.falcon-logo {
  padding-top: 16px;
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: right;

  & > img {
    width: 156px;
  }
}
.oval {
  width: 8px;
  height: 8px;
  background-color: #7ed321;
  border-radius: 50%;
  display: inline-block;
}
</style>
